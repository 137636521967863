// redback 1 overrides for text things from tinymce

// generics
.redback1-override {
	h4 {
		font-weight: bold;
		font-size: 1.5rem;
		line-height: 2rem;
		margin-bottom: 1rem;
	}

	ul {
		margin-left: 2rem;

		li {
			list-style: disc;
		}
	}

	>div:first-child {
		margin-right: 1rem;
	}
}

// technical info box from rb1
.redback1-override.technical {
	@apply mt-8; 
	@screen lg {
		@apply ml-16 mt-0;
	}

	ul {
		margin-left: 0;
		@apply text-sm bg-gray-50 rounded border border-gray-200 divide-y divide-gray-200;

		li {
			list-style: none;
			@apply py-2 px-4;
		}
	}
}