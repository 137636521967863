.logo {
	span {
		height: 32px!important;
		width:160px!important;
	}
	@screen lg {
		span {
			width:initial!important;
			height:initial!important;
		}
	}
}