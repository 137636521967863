@media (min-width: 1024px) {
	header {
		nav {
			li {
				.dropdown-panel {
					display: none;
				}

				&:hover {
					.dropdown-panel {
						display: block;
					}

					ul {
						li {
							.dropdown-panel {
								display: none;
							}

							&:hover {
								.dropdown-panel {
									display: block;
								}
							}

							&.active {
								.icon {
									opacity: 1;
								}

								.dropdown-panel {
									display: block;
								}
							}
						}
					}
				}
			}
		}
    }

    .menu-dropdown-bg-override {
        background: linear-gradient(to left, #fff 75%, #F8FAFC 55%);
    }

    .min-height-640 {
        min-height: 640px;
    }

    .lg\:columns-1 {
		column-count: 1;
	}

	.lg\:columns-2 {
		column-count: 2;
	}

	.lg\:columns-3 {
		column-count: 3;
	}
}

.dropdown-inner-content {
	@media (max-height: 820px) {
		min-height: 560px;
        max-height: 560px;
		overflow-y: auto;
	}
}
