h1, h2, h3, h4, h5, h6 {
    font-family: effra, 'Montserrat', sans-serif;
    font-weight: 600;
    font-style: normal;
}

h1 em, h2 em, h3 em, h4 em, h5 em, h6 em {
    font-family: effra, 'Montserrat', sans-serif;
    font-weight: 600;
    font-style: italic;
}

h1 {
    @apply text-4xl;

    @screen lg {
        @apply text-5xl;
    }
}

h2 {
    @apply text-3xl;

    @screen lg {
        @apply text-4xl;
    }
}

h3 {
    @apply text-2xl;

    @screen lg {
        @apply text-3xl;
    }
}

h4 {
    @apply text-xl;

    @screen lg {
        @apply text-2xl;
    }
}

h5 {
    @apply text-lg;

    @screen lg {
        @apply text-xl;
    }
}

h6 {
    @apply text-base;

    @screen lg {
        @apply text-lg;
    }
}