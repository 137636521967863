.homepage-slider {
	.bordered-slider {
		border: 1px solid rgba(209, 213, 219, var(--tw-border-opacity)) !important;
		border-radius: 0.25rem !important;
	}

	.swiper-slide>div {
		height: 100%;
	}
}

// Styles to override the default swiper slider styles for the product page
.product-page-main-image {
	.swiper-pagination{
		@apply bg-white text-gray-600 px-2 rounded;
	}
}

.product-page-thumbnail-slider {
	.product-page-thumbnail-image {
		border: 1px solid rgba(203, 213, 225, var(--tw-border-opacity))!important;
		@apply bg-white w-full rounded-lg border border-gray-300 object-contain;
	}
	.swiper-slide-thumb-active span:before {
		content: "";
		@apply absolute bottom-0 top-0 left-0 right-0 border-2 border-brand-secondary rounded-lg z-10;
	}
}


div.swiper-button-prev,
div.swiper-button-next {
	color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.small-swiper{
	max-width:320px;
}
.large-swiper{
	max-width:500px;
}
// .homepage-swiper{
// 	max-width:1000px;
// }
.homepage-mobile-swiper{
	max-width:100vw;
}
@media (min-width: 500px) {
	.homepage-mobile-swiper{
		max-width:500px;
	}
}
@media (min-width: 765px) {
	.homepage-mobile-swiper{
		max-width:765px;
	}
}

/* purgecss start ignore */
.swiper-thumbs .swiper-slide {
    width: auto !important;
}

.swiper-button-next,
.swiper-button-prev {
	@apply hidden text-brand-primary hover:text-brand-secondary active:text-brand-tertiary transition duration-150 ease-in-out;
	
	@screen lg {
		@apply flex
	}
}
.swiper-button-prev:after,
.swiper-button-next:after {
	@apply text-2xl;
}

.swiper-pagination-bullet {
	@apply border border-brand-tertiary mx-6 transition duration-150 ease-in-out;

	&:hover {
		@apply bg-brand-tertiary;
	}
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    display: table;
    width: auto;
    margin: 0 auto;
    right: 0;
}
/* purgecss end ignore */