@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

// Effra Heading Font
@import url("https://use.typekit.net/iju3pcr.css");

// Montserrat Body Type Font
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);

// import swiper code here so its visible to swiper compnent and gets pruned accordingly
// @import url(https://unpkg.com/swiper@8/swiper-bundle.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/Swiper/11.0.5/swiper-bundle.min.css);

@import "./fontawesome/scss/fontawesome.scss";
@import "./fontawesome/scss/solid.scss";
@import "./fontawesome/scss/brands.scss";

@import "./base/typography.scss";
@import "./base/links.scss";
@import "./base/utilities.scss";

@import "./components/buttons.scss";
@import "./components/footer.scss";
@import "./components/forms.scss";
@import "./components/header.scss";
@import "./components/home-feature-panels.scss";
@import "./components/navigation.scss";
@import "./components/reviews-io.scss";
@import "./components/swiper-slider.scss";
@import "./components/tables.scss";

// these are for old styles forom rb1 tinymce
@import "./rb1-overrides.scss";

// jm overrides here

#__next,
#__next .motion-animate-wrapper:first-child,
#__next .motion-animate-wrapper:first-child .wrapper:first-child {
	min-height: 100vh;
	// min-width:100vw;
}

#mobileMenuDropdown {
	min-height: 400px;
}

.new-update-background {
	background-image: url(/assets/images/bg-new-update.jpg);
}

#gateway_iframe_wrapper iframe {
	width: 100%;
	height: 100%;
	min-height: 100vh;
}

@media (max-width: 1023px) {
	iframe {
		max-width: 100%;
	}
}

.top-80px {
	top: 80px
}

/* purgecss start ignore */
.popup-overlay {
	background-color: rgba(0, 0, 0, 0.7);
}

/* purgecss end ignore */

// jm overrides above here

html,
body {
	height: 100%;
}

body {
	font-family: 'Montserrat', sans-serif;
	@apply bg-gray-100;
}

.icon {
	display: inline-block;
	font-style: normal;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;

	&.icon-search {
		background-image: url(/assets/images/icon-search.svg);
	}

	&.icon-cart {
		background-image: url(/assets/images/icon-cart.svg);
	}

	&.icon-down-arrow {
		background-image: url(/assets/images/icon-arrow-down.svg);
	}

	&.icon-trustpilot {
		background-image: url(/assets/images/icon-trustpilot-stars-5-1.svg);
	}

	&.icon-360-view {
		background-image: url(/assets/images/icon-360-view.svg);
	}

	&.icon-twitter-square {
		background-image: url(/assets/images/icon-twitter-square.svg);
	}

	&.icon-linkedin-square {
		background-image: url(/assets/images/icon-linkedin-square.svg);
	}

	&.icon-facebook-square {
		background-image: url(/assets/images/icon-facebook-square.svg);
	}

	&.icon-instagram {
		background-image: url(/assets/images/icon-instagram.svg);
	}

	&.icon-add-collection {
		background-image: url(/assets/images/icon-add-collection.svg);
	}

	&.icon-part-identification {
		background-image: url(/assets/images/icon-part-identification.svg);
	}

	&.icon-water-heater {
		background-image: url(/assets/images/icon-water-heater.svg);
	}

	&.icon-water-cylinder {
		background-image: url(/assets/images/icon-water-cylinder.svg);
	}

	&.icon-new-customer {
		background-image: url(/assets/images/icon-new-customer.svg);
	}

	&.icon-add-collection-dark {
		background-image: url(/assets/images/icon-add-collection-dark.png);
	}

	&.icon-arrow-left {
		background-image: url(/assets/images/icon-arrow-left.png);
	}

	&.icon-search-home {
		background-image: url(/assets/images/icon-search-home.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50%;
	}

	&.icon-twitter-dark {
		background-image: url(/assets/images/icon-twitter-dark.svg);
	}

	&.icon-linkedin-dark {
		background-image: url(/assets/images/icon-linkedin-dark.svg);
	}

	&.icon-facebook-dark {
		background-image: url(/assets/images/icon-facebook-dark.svg);
	}
}

.search {
	.input-search {
		@media (min-width: 1024px) {
			min-width: 35rem;
		}

		&::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			font-style: italic;
		}

		&:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			font-style: italic;
		}

		&::-ms-input-placeholder {
			/* Microsoft Edge */
			font-style: italic;
		}
	}
}

.dropdown:hover .dropdown-menu {
	display: block;
}

.has-tooltip:hover .has-tooltip-hover\:flex {
	display: flex;
}

.tab-content {
	display: none;
}

.tab-content.active {
	display: block;
}

.bg-img-banner {
	background-position: 100% 0;
	background-repeat: no-repeat;
	background-size: contain;
}

.max-h-0 {
	max-height: 0;
}

input[type=range]::-webkit-slider-thumb {
	pointer-events: all;
	width: 24px;
	height: 24px;
	-webkit-appearance: none;

	/* @apply w-6 h-6 appearance-none pointer-events-auto; */
}

.bg-img-catalogue {
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
}

.opacity-60 {
	opacity: 0.6;
}

.whitespace-no-wrap {
	white-space: nowrap;
}

select.appearance-none {
	appearance: auto;
}

.img-latest-blog {
	span {
		width: 100% !important;
	}
}

.loader {
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #105E76;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite;
	/* Safari */
	animation: spin 2s linear infinite;
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 50px;
	color: white;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
	cursor: pointer;
}

.loader-img-comp {
	position: fixed;
	z-index: 999;
	background: transparent;
	height: 100%;
	width: 100%;
	display: grid;
	place-content: center;
}


/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

input[type="number"]::-webkit-inner-spin-button {
	height: 70px;
}

.top-banner{
	color:white;
	width:100%;
	z-index: 999;
	padding: 10px;
	text-align: center;
}

.footer-icons img{
	width: 80px;
	height: 80px;
	object-fit: contain;
}

/*cookie popup styles start*/

.cookies-popup {

	bottom: 0;
	left: 0;
	position: fixed;
	width: 100%;
	box-sizing: border-box;
	z-index: 9999;
	background: #fff;

	.cookies-info-holder {
		border-color: #f4f4f4;
		background-color: #fff;
		border-bottom: 1px solid #f4f4f4;
		padding: 16.5px 24px;
		box-shadow: 0 -1px 10px 0 #acabab4d;
	}
	
	.title {
		color: #212121;
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		margin: 0 0 10px 0;
	}

	.notice-group {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 767px) {
			display: block;
		}
	}

	.notice-des {
		color: #212121;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
        padding-right: 150px;

        @media (max-width: 1200px) {
            padding-right: 20px;
        }

        @media (max-width: 767px) {
            padding-right: 0;
        }

		p {
			margin: 0;

			@media (max-width: 767px) {
				margin: 0 0 16px;
			}
		}
	}

	.notice-btn-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 15px;
		width: 480px;
		flex-shrink: 0;

		@media (max-width: 767px) {
			width: 100%;
			margin: 0;
		}

		@media (max-width: 520px) {
			display: block;
		}

		&.active {
			.cky-btn-customize {
				&:after {
					transform: rotate(0deg);
				}
			}
		}
	}

	.cky-btn {
		font-size: 14px;
		font-family: inherit;
		line-height: 24px;
		padding: 8px 27px;
		font-weight: 500;
		margin: 0 8px 0 0;
		border-radius: 2px;
		border: 2px solid rgb(16, 94, 118);
		cursor: pointer;
		text-align: center;
		text-transform: none;
		color: #fff;
		border-radius: 3px;
		background: rgb(16, 94, 118);

		@media (max-width: 767px) {
			margin: 0 2px;
			width: 33.333333333%;
		}

		@media (max-width: 520px) {
			width: 100%;
			margin: 0 0 10px;
		}
	}

	.cky-btn-preferences {
		@media (max-width: 767px) {
			width: 100%;
		}
	}

	.cky-btn-customize {
		padding: 8px 28px 8px 14px;
		position: relative;

		&:after {
			position: absolute;
			content: "";
			display: inline-block;
			top: 18px;
			right: 12px;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 6px solid;
			border-top-color: #fff;
			transform: rotate(180deg);
			transition: transform 0.3s ease;
		}
	}

	// .preference-holder {
	// 	display: none;
	// }

	.preference {
		padding: 0 24px;
		color: #212121;
		overflow-y: scroll;
		max-height: 51vh;
	}

	.preference-header {
		margin: 16px 0 0 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.preference-title {
		font-size: 18px;
		font-weight: 700;
		line-height: 24px;
		color: #212121;
	}

	.preference-body-wrapper {
		border-color: inherit;
	}

	.preference-content-wrapper {
		font-size: 18px;
		line-height: 24px;
		font-weight: 400;
		padding: 12px 0;
		border-bottom: 1px solid #f4f4f4;

		p {
			margin-bottom: 16px;
		}
	}

	.accordion-wrapper {
		margin-bottom: 10px;
	}

	.cky-accordion {
		cursor: pointer;
		padding-left: 22px;
		border-bottom: 1px solid #f4f4f4;

		&.open {
			.accordion-chevron {
				img {
					transform: rotate(0);
				}
			}
		}
	}

	.accordion-item {
		margin-top: 10px;
	}

	.accordion-body {
		display: none;
	}

	.accordion-chevron {
		top: 0;
		left: -3px;
		position: absolute;

		img {
			display: block;
			height: auto;
			max-width: 100%;
			transform: rotate(-80deg);
			transition: transform 0.3s ease;	
		}
	}

	.accordion-header-wrapper {
		cursor: pointer;
		width: 100%;
	}

	.accordion-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.cky-accordion {
		position: relative;

		&.padding-right {
			padding-right: 45px;
		}
	}

	.accordion-title {
		font-size: 16px;
		font-family: inherit;
		color: #212121;
		line-height: 24px;
		background: none;
		border: none;
		font-weight: 700;
		padding: 0;
		margin: 0;
		cursor: pointer;
		text-transform: none;
		min-height: 0;
		text-shadow: none;
		box-shadow: none;
	}

	.accordion-content {
		// display: none;
		margin-bottom: 16px;
	}

	.always-active {
		color: #008000;
		font-weight: 600;
		line-height: 24px;
		font-size: 14px;
	}

	.accordion-header-des {
		color: #212121;
		font-size: 18px;
		line-height: 24px;
		margin: 10px 0 16px 0;

		p {
			margin-bottom: 16px;
		}
	}

	.switch {
		top: 0;
		right: 0;
		position: absolute;
	}

	input[type="checkbox"] {
		position: relative;
		width: 44px;
		height: 24px;
		margin: 0;
		background: #d0d5d2;
		background-color: rgb(208, 213, 210);
		-webkit-appearance: none;
		border-radius: 50px;
		cursor: pointer;
		outline: 0;
		border: none;
		top: 0;

		&:checked {
			background: #c4ab95;
			&:before {
				transform: translateX(20px);
			}
		}

		&:before {
			position: absolute;
			content: "";
			height: 20px;
			width: 20px;
			left: 2px;
			bottom: 2px;
			border-radius: 50%;
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
			margin: 0;
		}
		&:after {
			display: none;
		}
	}

	.footer-wrapper {
		position: relative;
	}

	.footer-shadow {
		display: block;
		width: 100%;
		height: 40px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
		position: absolute;
		bottom: 100%;
	}

	.prefrence-btn-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 18px 24px;
		border-top: 1px solid #f4f4f4;
	}

	.cky-audit-table {
		background: #f4f4f4;
		background-color: rgb(244, 244, 244);
		border-radius: 6px;
		color: #212121;
		border-color: #ebebeb;
		background-color: #f4f4f4;
	}

	.cky-cookie-des-table {
		font-size: 14px;
		line-height: 24px;
		font-weight: normal;
		padding: 15px 10px;
		border-bottom: 1px solid rgb(235, 235, 235);
		margin: 0;

		li {
			list-style-type: none;
			display: flex;
			padding: 3px 0;

			&:first-child {
				padding-top: 0;
			}

			div {
				&:first-child {
					width: 100px;
					font-weight: 600;
					word-break: break-word;
					word-wrap: break-word;
				}
				&:last-child {
					flex: 1;
					word-break: break-word;
					word-wrap: break-word;
					margin-left: 8px;
				}
			}
		}
	}
}

/*.cky-btn-customize-style{
	color: #; border-color: #33C1C4; background-color: #33C1C4;
}

.cky-btn-reject-style{
	color: #0A0B11; border-color: #33C1C4; background-color: #33C1C4;
}

.cky-btn-accept-style{
	color: #0A0B11; border-color: #33C1C4; background-color: #33C1C4;
}

.cky-audit-table-style{
	color: #212121; border-color: #ebebeb; background-color: #f4f4f4;
}

.cky-btn-preferences-style{
	color: #0A0B11; border-color: #33C1C4; background-color: #33C1C4;
}*/
/*cookie popup styles ends*/

.m-0-important {
    margin: 0 !important;
  }